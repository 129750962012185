import { useEffect, useState, Fragment } from "react";
import Chart from "react-apexcharts";
import { dashStyles } from "../../theme";
import { getDashboardView } from "../../services/api";
import {
  CircularProgress,
} from "@mui/material";
import { SimpleResponse } from "../../services/interfaces";
import "../../style.css";
import * as _ from "lodash"
import { ApexOptions } from "apexcharts";
import WrongData from "./wrongquery";
import EmptyData from "./empty"
import { date } from "./masking";

export default function BarChart(props: any) {
  const dashClasses = dashStyles();
  const [categories, setCategories] = useState([]);
  const [series, setSeries] = useState([]);
  const [dataLoad, setDataLoad] = useState(false);
  const [wrongData, setWrongData] = useState(false);
  const [emptyData, setEmptyData] = useState(false);

  let dataMessage = [
    "LABEL - texto da legenda",
    "DATA - valor mostrado no gráfico"
  ];

  function getData(targetUrl: string) {
    setDataLoad(true);
    getDashboardView(targetUrl).then((response: SimpleResponse) => {
      if (response.statusCode === 504 || response.statusCode === 500) {
        setWrongData(true);
      } else {
        let rawData = response.results.map((x: any) => {
          return [x.data];
        }).flat();
        if(rawData.length === 0){
          setEmptyData(true);
        }else{
          if (rawData[0][0].LABEL === undefined || rawData[0][0].DATA === undefined) {
            setWrongData(true);
          }
          setCategories(rawData[0].map((x: any) => {
            return date(x.LABEL);
          }));
          setSeries(
            rawData.map((x: any, index: number) => {
              return {
                name: response.results[index].originName ?? "",
                data: rawData[index].map((x: any) => {
                  return x.DATA;
                }),
              }
            }));
        }
        setDataLoad(false);
        queryTimeout(props.expiration);
      }
    });
  }

  function queryTimeout(timeout: number) {
    setTimeout(() => {
      getData(props.url);
    }, timeout);
  }

  const options : ApexOptions = {
    tooltip: {
      y: {
        formatter: function (value: any) {
          return props.mask(value);
        }
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: categories,
    },
    yaxis:{
      labels:{
        formatter: function (value: any) {
          return props.mask(value);
        }
      }
    },
    fill: {
      opacity: 1,
    },
  };

  useEffect(() => {
    getData(props.url);
    setWrongData(false);
    setEmptyData(false);
    return function cleanup() {
      setDataLoad(true);
    };
  }, [props.url]);

  return (
    <Fragment

    >
     
      {
        wrongData ?
          <WrongData dataprops={dataMessage} />
          :
          <div>
            {dataLoad ? (
              <div
                style={{ padding: 30, justifyContent: "center", display: " flex" }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Fragment>{emptyData ? <EmptyData /> : <Chart options={options} series={series} type="bar" />}</Fragment>
              
            )}
          </div>
      }
    </Fragment>
  );
}