import {
  BaseGraphic,
  GroupInterface,
  PassData,
  Seller,
  User,
  UserInfo,
  View,
} from "./interfaces";
import { sha256 } from "js-sha256";
import { getValue } from "./auth";
import LogOff from "components/logoff/logoff";

//CONSTS FOR SETTING API ROUTE
//const base_address: string = "http://178.238.225.205:5000";
const base_address: string = "/ws";
//const base_address: string = "http://127.0.0.1:5000";
const api_address: string = `${base_address}/api`;


const noAuthHeader = new Headers({
  "Content-Type": "application/json",
});

//AUTH

export async function doLogin(loginInfo: UserInfo) {
  const sendInfo: UserInfo = {
    login: loginInfo.login,
    email: loginInfo.email, 
    password: sha256(loginInfo.password),
  };

  return await fetch(`${api_address}/auth`, {
    method: "post",
    body: JSON.stringify(sendInfo),
    headers: noAuthHeader,
  }).then((res) => res.json());
}

export async function doLoginasUser(loginInfo: UserInfo) {
  const sendInfo: UserInfo = {
    login: loginInfo.login,
    email: loginInfo.email, 
    password: sha256(loginInfo.password),
  };

  return await fetch(`${api_address}/auth/loginasuser`, {
    method: 'post',
    body: JSON.stringify(sendInfo),
    headers: noAuthHeader,
  }).then((res) => res.json());
}

export async function logoffApi() {
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/auth`, {
    method: "delete",
    headers: authHeader,
  }).then((res) => {
    const resjson =  res.json();
    return resjson;
  });
}

export async function postLoginSupport(loginInfo: UserInfo) {
  const sendInfo: UserInfo = {
    login: loginInfo.login,
    email: loginInfo.email,
    password: sha256(loginInfo.password),
  };

  return await fetch(`${api_address}/auth?support=true`, {
    method: "post",
    body: JSON.stringify(sendInfo),
    headers: noAuthHeader,
  }).then((res) => res.json());
}

export async function getStoreIDs() {
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/origins/databases/`, {
    method: "get",
    headers: authHeader,
  }).then((res) => res.json());
}

//USERS

export async function changeName(newName: string, targetMail: string) {
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(
    `${api_address}/users/name?newName=${newName}&email=${targetMail}`,
    { method: "PATCH", headers: authHeader }
  );
}
export async function changeGroup(newGroup: string, targetMail: string) {
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(
    `${api_address}/users/group?email=${targetMail}&groupid=${newGroup}`,
    { method: "PATCH", headers: authHeader }
  );
}

export async function changeLogin(newLogin: string, targetMail: string) {
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(
    `${api_address}/users/login?email=${targetMail}&newLogin=${newLogin}`,
    { method: "PATCH", headers: authHeader }
  );
}

export async function changePassword(targetMail: string, passData: PassData) {
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  const body: Object = {
    email: targetMail,
    oldPassword: sha256(passData.oldPassword),
    newPassword: sha256(passData.newPassword),
    compareNewPassword: sha256(passData.compareNewPassword),
  };

  return await fetch(`${api_address}/users/password`, {
    method: "PATCH",
    headers: authHeader,
    body: JSON.stringify(body),
  });
}

export async function changePasswordWithoutConfirm(
  targetMail: string,
  password: string
) {
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  const body: Object = {
    email: targetMail,
    oldPassword: "nao-precisa",
    newPassword: sha256(password),
    compareNewPassword: sha256(password),
  };
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/users/password`, {
    method: "PATCH",
    headers: authHeader,
    body: JSON.stringify(body),
  });
}

export async function changeMail(userMail: string, newMail: string){
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(
    `${api_address}/users/email?email=${userMail}&newEmail=${newMail}`,
    { method: "PATCH", headers: authHeader }
  );
}

export async function getUsers() {
  const authHeader = new Headers({});
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/users`, {
    headers: authHeader,
  }).then((response) => response.json());
}

export async function deleteUser(target: string) {
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/users?email=${target}`, {
    method: "delete",
    headers: authHeader,
  });
}

export async function PostUser(newUser: User) {
  let sendUser: User = {
    name: newUser.name,
    email: newUser.email,
    login: newUser.login,
    password: sha256(newUser.password),
    store: {
      storeId: "",
    },
    idGroupGraphic: newUser.idGroupGraphic,
  };
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/users`, {
    method: "post",
    headers: authHeader,
    body: JSON.stringify(sendUser),
  });
}

export async function getClientsData(currentStore: string) {
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/origins/clients?storeId=${currentStore}`, {
    method: "get",
    headers: authHeader,
  }).then((res) => res.json());
}

export async function getUsersData() {
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/users/userlogin`, {
    method: "get",
    headers: authHeader,
  }).then((res) => res.json());
}

export async function getUserData() {
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/users/userlogin`, {
    method: "get",
    headers: authHeader,
  }).then((res) => res.json());
}
export async function restoreUser(target: string) {
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/users?email=${target}&activity=true`, {
    method: "delete",
    headers: authHeader,
  });
}

//VIEWS

export async function getView() {
  const authHeader = new Headers({});
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/graphics`, {
    headers: authHeader,
  }).then((response) => response.json());
}

export async function deleteView(target: string) {
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/graphics/${target}/false`, {
    method: "delete",
    headers: authHeader,
  });
}

export async function postView(newView: View) {
  let sendView: View = {
    name: newView.name,
    baseGraphicId: newView.baseGraphicId,
    originIds: newView.originIds,
    expirationQuery: newView.expirationQuery,
    defaultInterval: newView.defaultInterval,
    params: newView.params
  };
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/graphics`, {
    method: "post",
    headers: authHeader,
    body: JSON.stringify(sendView),
  });
}

export async function putView(targetView: View) {
  let sendView: View = {
    id: targetView.id,
    name: targetView.name,
    baseGraphicId: targetView.baseGraphicId,
    originIds: targetView.originIds,
    defaultInterval: Number(targetView.defaultInterval),
    expirationQuery: targetView.expirationQuery,
    params: targetView.params
  };
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/graphics`, {
    method: "put",
    headers: authHeader,
    body: JSON.stringify(sendView),
  });
}

export async function saveOrder(graphics: Array<any>) {
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/graphics/saveorder`, {
    method: "put",
    headers: authHeader,
    body: JSON.stringify(graphics),
  });  
}

//GROUPS

export async function getGroups() {
  const authHeader = new Headers({});
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/groupgraphics`, {
    headers: authHeader,
  }).then((response) => response.json());
}

export async function postGroup(newGroup: GroupInterface) {
  let sendGroup: GroupInterface = {
    name: newGroup.name,
    description: newGroup.description ? newGroup.description : "Sem descrição",
    idGraphics: newGroup.idGraphics,
  };
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/groupgraphics`, {
    headers: authHeader,
    body: JSON.stringify(sendGroup),
    method: "post",
  });
}

export async function patchGroup(editedGroup: GroupInterface) {
  let sendGroup: GroupInterface = {
    id: editedGroup.id,
    name: editedGroup.name,
    description: editedGroup.description
      ? editedGroup.description
      : "Sem descrição",
    idGraphics: editedGroup.idGraphics,
  };
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/groupgraphics`, {
    headers: authHeader,
    body: JSON.stringify(sendGroup),
    method: "put",
  });
}

export async function deleteGroup(target: string) {
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/groupgraphics/${target}/false`, {
    method: "delete",
    headers: authHeader,
  });
}

//CLIENTS

export async function getClients() {
  const authHeader = new Headers({});
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/origins/clients`, {
    headers: authHeader,
  }).then((response) => response.json());
}

export async function getOrigins() {
  const authHeader = new Headers({});
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/origins`, {
    headers: authHeader,
  }).then((response) => response.json());
}

//BASECHARTS

export async function getBaseCharts() {
  const authHeader = new Headers({});
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/basegraphics`, {
    headers: authHeader,
  }).then((response) => response.json());
}

export async function deleteBaseGraphic(target: string) {
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/baseGraphics/${target}`, {
    method: "delete",
    headers: authHeader,
  });
}

export async function postBaseGraphic(BaseGraphicData: BaseGraphic) {
  const baseSend: BaseGraphic = {
    id: BaseGraphicData.id,
    description: BaseGraphicData.description,
    height: {
      large: BaseGraphicData.height.large,
      medium: BaseGraphicData.height.medium,
      small: BaseGraphicData.height.small,
    },
    dateRequired: BaseGraphicData.dateRequired,
    width: {
      large: BaseGraphicData.width.large,
      medium: BaseGraphicData.width.medium,
      small: BaseGraphicData.width.small,
    },
    originsType: BaseGraphicData.originsType,
    name: BaseGraphicData.name,
    sql: BaseGraphicData.sql,
    mask: BaseGraphicData.mask,
    timeOutQuery: BaseGraphicData.timeOutQuery,
    type: BaseGraphicData.type,
    headers: BaseGraphicData.headers,
  };
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/basegraphics/`, {
    method: "post",
    body: JSON.stringify(baseSend),
    headers: authHeader,
  });
}

export async function putBaseGraphic(BaseGraphicData: BaseGraphic) {
  const baseSend: BaseGraphic = {
    id: BaseGraphicData.id,
    description: BaseGraphicData.description,
    height: {
      large: BaseGraphicData.height.large,
      medium: BaseGraphicData.height.medium,
      small: BaseGraphicData.height.small,
    },
    dateRequired: BaseGraphicData.dateRequired,
    width: {
      large: BaseGraphicData.width.large,
      medium: BaseGraphicData.width.medium,
      small: BaseGraphicData.width.small,
    },
    originsType: BaseGraphicData.originsType,
    name: BaseGraphicData.name,
    sql: BaseGraphicData.sql,
    mask: BaseGraphicData.mask,
    timeOutQuery: BaseGraphicData.timeOutQuery,
    type: BaseGraphicData.type,
    headers: BaseGraphicData.headers,
  };
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/basegraphics/`, {
    method: "put",
    body: JSON.stringify(baseSend),
    headers: authHeader,
  });
}

//SELLERS

export async function getSellersData() {
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/users/userloginseller`, {
    method: "get",
    headers: authHeader,
  }).then((res) => res.json());
}

export async function PostSeller(newSeller: Seller) {
  let sendUser: Seller = {
    name: newSeller.name,
    email: newSeller.email,
    login: newSeller.login,
    password: sha256(newSeller.password),
    store: {
      storeId: newSeller.store.storeId,
      database: newSeller.store.storeId,
    },
  };
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/users`, {
    method: "post",
    headers: authHeader,
    body: JSON.stringify(sendUser),
  });
}

//DATABASES

export async function postDatabase(newDBname: string, targetStore: string) {
  const sendDB = {
    name: newDBname,
    storeId: targetStore,
  };
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/origins`, {
    method: "post",
    body: JSON.stringify(sendDB),
    headers: authHeader,
  });
}

export async function deleteDatabase(target: string) {
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/origins/${target}/false`, {
    method: "delete",
    headers: authHeader,
  });
}

//DASHBOARD

export async function getDashboard() {
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${api_address}/dashboard`, {
    method: "get",
    headers: authHeader,
  }).then((res) => {
    if (res.status == 401){
      LogOff();
    }
    return res.json();
  });
}

export async function getDashboardView(viewUrl: string) {
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  return await fetch(`${base_address}${viewUrl}`, {
    method: "get",
    headers: authHeader,
  }).then((res) => {
    const resjson = res.json();
    return resjson; 
  });
}

//PASSWORD RECOVERY

export async function getRecoveryPasswordEmail(targetMail: string) {
  return await fetch(
    `${api_address}/users/passwordrecovery?email=${targetMail}`,
    {
      method: "get",
    }
  ).then((res) => res.json());
}

export async function verifyRecoveryToken(token: any) {
  return await fetch(
    `${api_address}/users/passwordrecovery/validate?recoveryToken=${token}`,
    {
      method: "get",
    }
  ).then((res) => res.json());
}

export async function postNewForgotPassword(
  recoveryToken: string,
  passData: PassData
) {
  const authHeader = new Headers({
    "Content-Type": "application/json",
  });
  authHeader.append("Authorization", String(getValue("token")));
  const body: Object = {
    recoveryToken: recoveryToken,
    newPassword: sha256(passData.newPassword),
    compareNewPassword: sha256(passData.compareNewPassword),
  };

  return await fetch(`${api_address}/users/passwordrecovery/`, {
    method: "PATCH",
    headers: authHeader,
    body: JSON.stringify(body),
  }).then((res) => res.json());
}
